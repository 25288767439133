.valetCoupon {
    background: url('valet_coupon.svg') no-repeat;
    width: 16.5rem;
    height: 419px;
    background-size: contain;
}
 .couponContainer{
     position: relative;
 }
 .valetCouponCode {
    position: absolute;
    height: 260px !important;
    width: 260px !important;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
 }
 canvas {
    height: 260px !important;
    width: 260px !important;
 }
 .couponNumber1 {
     position: absolute;
     top: 163px;
     left: 51%;
     transform: translateX(-50%);
     width: 107.5px;
     height: 55px;
 }
 .couponNumber2 {
     position: absolute;
     color: black;
     bottom: 154px;
     right: 38px;
     font-size: 17px;
     width: 61px;
     height: 56px;
 }
 .couponNumber1 p, .couponNumber2  p{
     position: relative;
     top: 50%;
     transform: translateY(-50%);
     color: #FE8C00;
 }
 .couponNumber1 p {
     font-size: 28px;
     font-weight: 900;
 }
 .couponNumber2  p{
     font-weight: 900;
 }

 .venueLogo img {
    border-radius: 50%;
    width: 60px; 
    height: 60px;  
    border: 2px solid #FE8C00;
    object-fit: cover; 
    position: absolute;  
    top: 78px;
    z-index: 2;
    transform: translateX(-50%);
}