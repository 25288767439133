.couponContainer{
    position: relative;
}
.valetCouponCode {
    width: 160px !important;
    height: 160px !important;
    position: absolute;
    bottom: 53px;
    border-radius: 0;
    right: 50px;
}
canvas {
    height: 160px !important;
    width: 160px !important;
}
.couponNumber1 {
    position: absolute;
    top: 135px;
    left: 51%;
    transform: translateX(-50%);
    width: 107.5px;
    height: 55px;
}
.couponNumber2 {
    position: absolute;
    color: black;
    bottom: 154px;
    right: 38px;
    font-size: 17px;
    width: 61px;
    height: 56px;
}
.couponNumber1 p, .couponNumber2  p{
    position: relative;
    top: 63%;
    left: -2%;
    transform: translateY(-50%);
    color: black;
}
.couponNumber1 p {
    font-size: 28px;
    font-weight: 900;
}
.couponNumber2  p{
    font-weight: 900;
}
.venueLogo img {
    border-radius: 50%;
    width: 60px; 
    height: 60px;  
    border: 2px solid #FE8C00;
    object-fit: cover; 
    position: absolute;  
    top: 78px;
    z-index: 2;
    transform: translateX(-50%);
}
.punching {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 2px;
    margin-left: 10px;
    position: relative;
    top: 10px;
    border: 1px solid grey;
}